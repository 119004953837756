import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import Contact from "../components/ContactPage"

const ContactPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} bgOffset="-80" bgImageOverlay={true} />
          <Contact data={fields} />
      </Layout>
    );
};
export default withI18next()(ContactPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      frontmatter {
        slug
        title
      }
    }
    bgImage: file(relativePath: { eq: "bg/contact_bg.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }`